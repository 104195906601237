import React from 'react';
import UserAvatar from '@/components/UserAvatar';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import { faBars, faChevronDown, faCoins } from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';
import { Button, createComponent, Dropdown, DropdownDivider, DropdownItem, DropdownLink, Flex, IconFA, If, Slot, toClassName, useNotifications } from 'react-commons';

import style from '../index.module.scss';
import LoginButton from '@/components/LoginButton';
import Link from 'next/link';
import { useAuthMethods } from '@/lib/hooks/useAuthMethods';
import axios from 'axios';
import { useRouter } from 'next/router';

export default createComponent('UserStatus', { style }, function UserStatus ({ className }) {
  const elRef = useRef<HTMLDivElement | null>(null);
  const [ authState ] = useAuthStore();
  const { logout } = useAuthMethods();
  const router = useRouter();
  const { notify } = useNotifications({ lifeSpan: Number(process.env.NEXT_PUBLIC_NOTIFICATION_LIFESPAN) });

  const purgeCache = async () => {
    notify('Purging cache...');
    try {
      await axios.get(
        `/api/revalidate?path=${router.asPath}`
      );
    } catch (e) {
      notify('Error revalidating cache.', 'error');
    }

    notify('Cache purged. Reloading the page...', 'success');
    window.location.reload();
  };

  className = toClassName(className, '&--modern');

  return (
    <div className={className} ref={elRef}>
      {
        If(authState.user, () => (
          <Flex className='UserStatus__User' container gap0 alignCenter>
            <Flex>
              <Link href={`/member/profiles/${authState.user.displayName}`}>
                <div>
                  <UserAvatar 
                    small
                    src={authState.user.avatarUrl} 
                    displayName={authState.user.displayName}
                  />
                </div>
              </Link>
            </Flex>
            <Flex>
              <Dropdown right>
                <Slot name='trigger'>
                  <span className='OpenUserMenu'>
                    <IconFA small icon={faChevronDown} />
                  </span>
                </Slot>
                <Slot name='content'>
                  <DropdownLink href={`/member/profiles/${authState.user.displayName}?tab=favoriteGames`} onClick={() => true}>
                    My Favorites
                  </DropdownLink>
                  <DropdownLink href={'/account/user/edit'}>
                    Account Settings
                  </DropdownLink>
                  <DropdownLink href={'/account/user/edit/subscription'}>
                    Subscription
                  </DropdownLink>
                  <DropdownDivider />
                  <DropdownItem onClick={logout}>
                    Logout
                  </DropdownItem>
                  {
                    If(authState.user.isAdminUser, () => (
                      <>
                        <DropdownDivider />
                        <DropdownItem onClick={purgeCache}>
                          Purge Page Cache
                        </DropdownItem>
                      </>
                    )).EndIf()
                  }
                </Slot>
              </Dropdown>
            </Flex>
          </Flex>
        ))
          .Else(() => (
            <LoginButton />
          ))
          .EndIf()
      }
    </div>
  );
});
